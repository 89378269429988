import { Injectable } from '@angular/core';
import { UserPro } from '../../models/user-pro/user-pro';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { UserProApiService } from './user-pro-api-service';
import { AuthService } from '../../auth';

@Injectable({
    providedIn: 'root',
})
export class UserProService {
    private userPro: UserPro = new UserPro();
    private userProSubject = new BehaviorSubject<UserPro>(new UserPro());

    constructor(
        private userProApiService: UserProApiService,
        private authService: AuthService,
    ) {
        const storedUserPro = sessionStorage.getItem('userPro');

        if (storedUserPro) {
            this.userPro = UserPro.fromJson(JSON.parse(storedUserPro));
            this.userProSubject.next(this.userPro);
        }
    }

    public set(userPro: Partial<UserPro>): void {
        this.userPro = UserPro.fromJson(userPro);
        this.userProSubject.next(this.userPro);
        sessionStorage.setItem('userPro', JSON.stringify(this.userPro));
    }

    public reset(): void {
        this.userPro = new UserPro();
        this.userProSubject.next(this.userPro);
    }

    public get(): UserPro {
        return this.userPro;
    }

    public getObservable(): Observable<UserPro> {
        return this.userProSubject.asObservable();
    }

    /* CALL APIS */

    public createUserPro(userPro: UserPro): Observable<APICallReturn | UserPro | undefined> {
        this.set(userPro);

        if (userPro === undefined) {
            return of(undefined);
        }
        return this.userProApiService.createUserPro(userPro).pipe();
    }

    public updateUserPro(userPro: Partial<UserPro>): Observable<APICallReturn | UserPro | undefined> {
        if (userPro === undefined) {
            return of(undefined);
        }
        return this.userProApiService.updateUserPro(this.authService.getToken(), userPro).pipe(
            map((data: APICallReturn | UserPro | undefined) => {
                if (data instanceof UserPro) {
                    this.set(data);
                }
                return data;
            }),
        );
    }

    public getUserPro(token: string, id: string): Observable<APICallReturn | UserPro | undefined> {
        return this.userProApiService.getUserPro(token, id);
    }

    public getUsersPros(token: string): Observable<APICallReturn | UserPro[] | undefined> {
        return this.userProApiService.getUsersPros(token);
    }

    public deleteUserPro(token: string, id: string): Observable<APICallReturn | UserPro | undefined> {
        return this.userProApiService.deleteUserPro(token, id);
    }
}
