<div class="shadow">
    <ul class="px-6 pt-6 flex items-center space-x-10 h-full">
        <a
            [class.active-link]="this.actualTab === 'informations'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
            (click)="this.actualTab = 'informations'"
        >
            Informations
        </a>
        <a
            [class.active-link]="this.actualTab === 'calendar'"
            class="p-2 text-neutral-68 text-base font-medium transition-transform transform text-color-aidant-dark hover:text-[#0e3350] hover:scale-105 hover-link cursor-pointer"
            (click)="this.actualTab = 'calendar'"
        >
            Calendrier
        </a>
    </ul>
</div>
<div class="w-full flex justify-center mt-20" *ngIf="this.actualTab === 'informations'">
    <form [formGroup]="this.userForm!.userForm" class="w-1/2 xl:w-3/12">
        <div class="text-aidant-dark mb-20 flex justify-center font-black text-2xl">
            {{ this.isNew ? "Créer un utilisateur" : "Modifier l'utilisateur " }}
        </div>
        <ng-container *ngIf="this.user">
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="firstName" type="text" />
                <label for="inputtext">Prénom</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="lastName" type="text" />
                <label for="inputtext">Nom</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="email" type="text" />
                <label for="inputtext">Email</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="phoneNumber" type="text" />
                <label for="inputtext">Téléphone</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="address" type="text" />
                <label for="inputtext">Nom et numéro de voie</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="city" type="text" />
                <label for="inputtext">Ville</label>
            </span>
            <span class="p-float-label mb-12">
                <input pInputText class="w-full" formControlName="zipCode" type="text" />
                <label for="inputtext">Code postal</label>
            </span>
            <span class="p-float-label mb-12">
                <p-dropdown styleClass="w-full text-left" formControlName="status" [options]="this.userForm.status" ></p-dropdown>
                <label for="inputtext">Catégorie socioprofessionnelle</label>
            </span>
            <div class="mb-2 flex justify-center items-center">
                <button pButton label="Valider" type="submit" class="p-button-raised p-button-sm w-24" (click)="onSubmit()" [loading]="isLoading"></button>
            </div>
        </ng-container>
    </form>
</div>
<app-calendar-user [id]="getId()" *ngIf="this.actualTab === 'calendar'"></app-calendar-user>
<p-toast></p-toast>