import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, finalize, map, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
import { Thread } from 'src/app/core/models/thread/thread';
import { APICallReturn } from 'src/app/core/services/APICallReturn';
import { ThreadService } from 'src/app/core/services/thread/thread.service';

@Component({
  selector: 'app-threads-list',
  templateUrl: './threads-list.component.html',
  styleUrls: []
})
export class ThreadsListComponent implements OnInit {

  threads: Thread[] = [];
  search: string = '';
  isLoading = false;
  maxIterations = 0;
  constructor(
    private router: Router,
    private authService: AuthService,
    private threadService: ThreadService
  ) { }

  definedThreadsIterationNumber() {
    // const iterateThreads = (threads: Thread[]) => {
    //     threads.forEach(thread => {
    //       if (thread.threads && thread.threads.length > 0) {
    //         this.maxIterations++;
    //         thread.iterationLength = maxIterations;
    //         iterateThreads(thread.threads);
    //       }
    //     });
    //   };
    //   const maxIterations = 0;
    //   iterateThreads(this.threads);
}


  ngOnInit(): void {
    this.isLoading = true;
    this.threadService
      .getFirstThreads(this.authService.getToken())
      .pipe(
        map((thread: APICallReturn | Thread[] | undefined) => {
          if (Array.isArray(thread) && thread.length > 0 && thread[0] instanceof Thread) {
            this.threads = thread;
            console.log("te",thread)
          }
        }),
        finalize(() => (this.isLoading = false)),
        catchError(() => {
          this.isLoading = false;
          return of(null);
        }),
      )
      .subscribe();

      // this.definedThreadsIterationNumber()
  }

  delete(uuid: string): void { // fix on delete go to details (item row)
    this.threadService
    .deleteThread(this.authService.getToken(), uuid)
    .pipe(
      finalize(() => (this.router.navigate([`/threads`]))),
      catchError(() => {
        this.isLoading = false;
        return of(null);
      }),
    )
    .subscribe();
  }

  create(): void {
    this.router.navigate(['/thread/new']);
  }

  edit(uuid: string): void {
    this.router.navigate([`thread/${uuid}`]);
  }

}
