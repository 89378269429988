
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserForm } from '@lesaidantsbackoffice/core/forms';
import { User } from '@lesaidantsbackoffice/core/models';
import { UserService } from '@lesaidantsbackoffice/core/services/services';
import { MessageService } from 'primeng/api';
import { catchError, finalize, map, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth';
import { APICallReturn } from 'src/app/core/services/APICallReturn';

@Component({
    selector: 'app-user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
    providers: [MessageService],
})
export class UserEditorComponent implements OnInit {
    public userForm: UserForm = new UserForm();
    public user: User = new User();
    public isNew: boolean = true;
    public id?: string;
    public isLoading: boolean = false;
    public actualTab: string = "informations"
    constructor(
        private activatedRoute: ActivatedRoute,
        private messageService: MessageService,
        private userService: UserService,
        private router: Router,
        private authService: AuthService,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.id = params.get('id') || 'isNew';
            this.isNew = this.id === 'isNew';
        });
        if (!this.isNew) {
            this.isLoading = true;
            this.userService
                .getUser(this.authService.getToken(), this.id || '')
                .pipe(
                    map((user: APICallReturn | User | undefined) => {
                        if (user instanceof User) {
                            this.user = user;
                            this.userForm.fromUser(user);
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.isLoading = false;
                        this.router.navigate([`404`]);
                        return of(null);
                    }),
                )
                .subscribe();
        }
    }

    public getId(): number {
        return Number(this.id);
    }

    public create() {
        this.isLoading = true;
        this.userService
            .createUser(this.userForm.toUser(), this.authService.getToken())
            .pipe(
                map((user: APICallReturn | User | undefined) => {
                    if (user instanceof User) {
                        this.router.navigate([`user/${user.mainUserId}`]);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Utilisateur créé',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: "Erreur lors de la création de l'utilisateur",
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    public edit() {
        this.isLoading = true;
        this.userService
            .updateUser(this.authService.getToken(), this.userForm.toUser(), this.id || '')
            .pipe(
                map((user: APICallReturn | User | undefined) => {
                    if (user instanceof User) {
                        this.user = user;
                        this.userForm.fromUser(user);
                        this.messageService.add({
                            severity: 'success',
                            detail: 'Utilisateur mis à jour',
                        });
                    }
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.messageService.add({
                        severity: 'error',
                        detail: "Erreur lors de la mise à jour de l'utilisateur",
                    });
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    public onSubmit(): void {
        this.userForm.form.get('password')?.clearValidators();
        this.userForm.form.get('passwordChecker')?.clearValidators();
        this.userForm.form.get('isValidated')?.clearValidators();
        this.userForm.form.get('isProfessional')?.clearValidators();
        this.userForm.form.get('password')?.updateValueAndValidity();
        this.userForm.form.get('passwordChecker')?.updateValueAndValidity();
        this.userForm.form.get('isValidated')?.updateValueAndValidity();
        this.userForm.form.get('isProfessional')?.updateValueAndValidity();

        if (this.userForm?.form.valid && this.isNew) {
            this.create();
        } else if (this.userForm?.form.valid) {
            this.edit();
        } else {
            this.userForm?.markAsDirty();
        }
    }
}
