import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/service/AuthService';
import { CalendarItemApiService } from './calendar-item-api.service';
import { CalendarItem } from '../../models/calendarItem/calendarItem';

@Injectable({
  providedIn: 'root'
})
export class CalendarItemService {

  constructor(private authService: AuthService, private calendarItemApi: CalendarItemApiService) { }


  public updateCalendarItem(model: CalendarItem) {
    return this.calendarItemApi.updateCalendarItem(this.authService.getToken(), model)
  }

  public getCalendarItems(id: number) {
    return this.calendarItemApi.getCalendarItems(id, this.authService.getToken())
  }
}
