import { Component, Input, OnInit, computed } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreadService } from 'src/app/core/services/thread/thread.service';
import { AuthService } from 'src/app/core/auth';
import { catchError, finalize, map, of } from 'rxjs';
import { APICallReturn } from 'src/app/core/services/APICallReturn';
import { Thread } from '@lesaidantsbackoffice/core/models';
import { ThreadForm } from '@lesaidantsbackoffice/core/forms';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-threads-editor',
    templateUrl: './threads-editor.component.html',
})
export class ThreadsEditorComponent implements OnInit {
    @Input() threads: Thread[] = [];
    uuid?: string;
    isNew: boolean = false;
    isLoading: boolean = false;
    thread: Thread = new Thread();
    threadForm: ThreadForm = new ThreadForm();
    items: MenuItem[] | undefined;
    newUUid?: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        public threadService: ThreadService,
        public authService: AuthService,
        private router: Router,
    ) {
      const navigation = this.router.getCurrentNavigation();
        if (navigation && navigation.extras.state) {
            this.newUUid = navigation.extras.state['newUUid'];
        }
    }

    delete() {
      let uuid = this.threadForm.form.get('uuid')?.value
      let isFirst = this.threadForm.form.get('previous')?.value == undefined ? true : false

      if (isFirst) {
        this.router.navigate([`/threads`]);
        this.isLoading = true;
        this.threadService
            .deleteThread(this.authService.getToken(), uuid)
            .pipe(
                map((thread: APICallReturn | Thread | undefined) => {
                    this.router.navigate([`/threads`]);
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
      }
      else {
        this.isLoading = true;
        this.threadService
            .deleteThread(this.authService.getToken(), uuid)
            .pipe(
                map((thread: APICallReturn | Thread | undefined) => {
                  this.router.navigateByUrl('/threads', { skipLocationChange: true }).then(() => {
                    this.router.navigate([`/thread/${this.threadForm.form.get('previous')?.value}`]);
                  });
                }),
                finalize(() => (this.isLoading = false)),
                catchError(() => {
                    this.isLoading = false;
                    return of(null);
                }),
            )
            .subscribe();
      }
    }


    save() {
      this.threadForm.markAsDirty();
        if (this.threadForm.form.valid) {
          this.isLoading = true;
          const thread = this.threadForm.toThread();
          console.log('thread to send', thread);
          this.threadService
              .updateThread(this.authService.getToken(), thread, this.uuid || '')
              .pipe(
                  map((thread: APICallReturn | Thread | undefined) => {
                      if (thread instanceof Thread) {
                          this.router.navigate([`/thread/${thread.uuid}`]);
                      }
                  }),
                  finalize(() => (this.isLoading = false)),
                  catchError(() => {
                      this.isLoading = false;
                      return of(null);
                  }),
              )
              .subscribe();
        }
    }

    create() {
      this.threadForm.markAsDirty();
      if (this.newUUid == undefined) {
        if (this.threadForm.form.valid) {
            this.isLoading = true;
            const thread = this.threadForm.toThread();
            console.log('thread to create', thread);
            this.threadService
                .createThread(this.authService.getToken(), thread)
                .pipe(
                    map((thread: APICallReturn | Thread | undefined) => {
                        if (thread instanceof Thread) {
                            this.router.navigate([`/thread/${thread.uuid}`]);
                        }
                    }),
                    finalize(() => (this.isLoading = false)),
                    catchError(() => {
                        this.isLoading = false;
                        return of(null);
                    }),
                )
                .subscribe();
              }
            } else {
              if (this.threadForm.form.valid) {
                this.isLoading = true;
                this.threadForm.form.patchValue({previous: this.newUUid});
                const thread = this.threadForm.toThread();
                console.log('thread to create with newUUID', thread);
                this.threadService
                    .createThread(this.authService.getToken(), thread)
                    .pipe(
                        map((thread: APICallReturn | Thread | undefined) => {
                            if (thread instanceof Thread) {
                                this.router.navigate([`/thread/${thread.uuid}`]);
                            }
                        }),
                        finalize(() => (this.isLoading = false)),
                        catchError(() => {
                            this.isLoading = false;
                            return of(null);
                        }),
                    )
                    .subscribe();
                  }
      }
    }

    getButtonLabel() {
        if (this.isNew && this.newUUid == undefined) return 'Créer';
        if (this.isNew && this.newUUid) return '+';
        else return 'Sauvegarder';
    }

    goToThread(uuid: any) {
        if (uuid == undefined) {
            this.router.navigateByUrl('/threads', { skipLocationChange: true }).then(() => {
                this.router.navigate([`/threads`]);
            });
        } else {
            this.router.navigateByUrl('/threads', { skipLocationChange: true }).then(() => {
                this.router.navigate([`/thread/${uuid}`]);
            });
        }
    }

    buildBreadCrumbs() {
        this.thread.threads?.forEach((t) => {
            this.items = [{ label: t.choice, url: `/thread/${t.threadUUID}` }];
            // this.items?.push({label: '', routerLink: `/thread/${t.threadUUID}`})
            // this.items?.push({label: t.choice, routerLink: `/thread/${t.threadUUID}`})
            // this.buildBreadCrumbs()
        });
        //   this.items = [
        //     {label:'Categories'},
        //     {label:'Sports'},
        //     {label:'Football'},
        //     {label:'Countries'},
        //     {label:'Spain'},
        //     {label:'F.C. Barcelona'},
        //     {label:'Squad'},
        //     {label:'Lionel Messi', url: 'https://en.wikipedia.org/wiki/Lionel_Messi'}
        // ];
        console.log('items', this.items);
    }

    addThread(uuid: string) {
      console.log('uuid', uuid);

      this.router.navigateByUrl('/threads', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/thread/new'], { state: { newUUid: uuid } });
    });
  }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((params) => {
            this.uuid = params.get('id') || 'new';
            this.isNew = this.uuid === 'new';
        });

        console.log('newUUid', this.newUUid);

        // this.buildBreadCrumbs()

        if (!this.isNew) {
            this.isLoading = true;
            this.threadService
                .getThread(this.authService.getToken(), this.uuid || '')
                .pipe(
                    map((thread: APICallReturn | Thread | undefined) => {
                        if (thread instanceof Thread) {
                            this.thread = thread;
                            console.log('thread', this.thread);
                            this.threadForm.fromThread(thread);
                        }
                    }),
                    finalize(
                        () =>
                            (this.isLoading = false),
                            // this.buildBreadCrumbs()
                    ),
                    catchError(() => {
                        this.isLoading = false;
                        this.router.navigate([`404`]);
                        return of(null);
                    }),
                )
                .subscribe();
        }
    }
}
