import { ApiCommonService } from '../common/api-common.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@lesaidants/env';
import { APICallReturn } from '../APICallReturn';
import { UserPro } from '../../models/user-pro/user-pro';

/**
 *@ignore
 */
@Injectable({
    providedIn: 'root',
})
/**
 * Class used to fill and send a User object
 */
export class UserProApiService extends ApiCommonService<UserPro> {
    /**
     * URL to ping
     */
    private baseUrl = `${environment.url}/${environment.version}/user-pro`;
    protected resourceUrl = `${environment.url}/${environment.version}/user-pro`;
    /**
     * Method returning a User created from json data
     */
    fromJson(json: any): UserPro {
        return UserPro.fromJson(json);
    }
    /**
     * Method returning any in a json format
     */
    toJson(model: UserPro): any {
        return UserPro.toJson(model);
    }
    /**
     * Method returning Observable UserPro get by id
     */
    getUserPro(token: string, id: string): Observable<APICallReturn | UserPro | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, id);
    }
    /**
     * Method returning Observable UsersPros list
     */
    getUsersPros(token: string): Observable<APICallReturn | UserPro[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }
    /**
     * Method returning Observable UserPro update by auth token
     */
    updateUserPro(token: string, userPro: UserPro): Observable<APICallReturn | UserPro | undefined> {
        this.resourceUrl = `${this.baseUrl}/update`;
        return super.update(token, userPro);
    }
    /**
     * Method returning Observable UserPro create by auth token
     */
    createUserPro(userPro: UserPro): Observable<APICallReturn | UserPro | undefined> {
        this.resourceUrl = `${this.baseUrl}/register`;
        return super.create(userPro);
    }
    /**
     * Method to delete UserPro by id
     */
    deleteUserPro(token: string, id: string): Observable<APICallReturn | UserPro | undefined> {
        this.resourceUrl = `${this.baseUrl}/delete/${id}`;
        return super.delete(token);
    }
}
