import { Injectable } from "@angular/core";
import { ApiCommonService } from "../common/api-common.service";
import { Thread } from "../../models/thread/thread";
import { environment } from "@lesaidants/env";
import { Observable, of } from "rxjs";
import { APICallReturn } from "../APICallReturn";
import { User } from "../../models/user/user";


@Injectable({
    providedIn: 'root',
})
export class ThreadApiService extends ApiCommonService<Thread> {
    private baseUrl = `${environment.url}/${environment.version}/chat-bot`;
    protected resourceUrl = `${environment.url}/${environment.version}/chat-bot`;

    // threads: Thread[] = [
    //   {
    //     id: 1,
    //     uuid: "1",
    //     parentUuid: undefined,
    //     index: 0,
    //     choice: "Les indemnitées journalières",
    //     answer: "Sachez que vous pouvez suivre votre arrêt de travail depuis votre compte ameli, rubrique ",
    //     threads: [
    //       {
    //         id: 2,
    //         uuid: "1.1",
    //         parentUuid: "1",
    //         index: 1,
    //         choice: "Arrêt maladie avec hospitalisation",
    //         answer: "vous allez être hospitalisé ",
    //         threads: [
    //           {
    //             id: 3,
    //             uuid: "1.1.1",
    //             parentUuid: "1.1",
    //             index: 2,
    //             choice: "Arrêt maladie avec bite",
    //             answer: "vous allez être mort ",
    //             threads: [
    //               {
    //                 id: 4,
    //                 uuid: "1.1.1.1",
    //                 parentUuid: "1.1.1",
    //                 index: 2,
    //                 choice: "Arrêt maladie avec bite2",
    //                 answer: "vous allez être mort ",
    //                 threads: [],
    //                 createdAt: new Date("2024-05-11T00:00:00Z"),
    //                 updatedAt: new Date("2024-05-11T00:00:00Z"),
    //                 createdBy: { firstName: "Emile", lastName: "Corvini" },
    //                 iterationLength: 0
    //               }
    //             ],
    //             createdAt: new Date("2024-05-11T00:00:00Z"),
    //             updatedAt: new Date("2024-05-11T00:00:00Z"),
    //             createdBy: { firstName: "Emile", lastName: "Corvini" },
    //             iterationLength: 0
    //           },
    //         ],
    //         createdAt: new Date("2024-05-11T00:00:00Z"),
    //         updatedAt: new Date("2024-05-11T00:00:00Z"),
    //         createdBy: { firstName: "Emile", lastName: "Corvini" },
    //         iterationLength: 0
    //       },
    //       {
    //         id: 4,
    //         uuid: "1.2",
    //         parentUuid: "1",
    //         index: 1,
    //         choice: "Arrêt maladie avec mort précoce",
    //         answer: "vous allez être hospitalisé ",
    //         threads: [],
    //         createdAt: new Date("2024-05-11T00:00:00Z"),
    //         updatedAt: new Date("2024-05-11T00:00:00Z"),
    //         createdBy: { firstName: "Emile", lastName: "Corvini" },
    //         iterationLength: 0
    //       },
    //     ],
    //     createdAt: new Date("2024-05-11T00:00:00Z"),
    //     updatedAt: new Date("2024-05-11T00:00:00Z"),
    //     createdBy: { firstName: "Emile", lastName: "Corvini" },
    //     iterationLength: 0
    //   },

    //   {
    //     id: 5,
    //     uuid: "2",
    //     parentUuid: undefined,
    //     index: 0,
    //     choice: "J'ai un problème avec mon dossier",
    //     answer: "ameliBot vous propose des tutoriels pour vous aider à y voir plus clair dans le suivi de votre dossier",
    //     threads: [
    //       {
    //         id: 6,
    //         uuid: "2.1",
    //         parentUuid: "2",
    //         index: 1,
    //         choice: "Première option",
    //         answer: "Réponse à la première option",
    //         threads: [],
    //         createdAt: new Date("2024-05-11T00:00:00Z"),
    //         updatedAt: new Date("2024-05-11T00:00:00Z"),
    //         createdBy: { firstName: "Emile", lastName: "Corvini" },
    //         iterationLength: 0
    //       },
    //       {
    //         id: 7,
    //         uuid: "2.2",
    //         parentUuid: "2",
    //         index: 1,
    //         choice: "Deuxième option",
    //         answer: "Réponse à la deuxième option",
    //         threads: [],
    //         createdAt: new Date("2024-05-11T00:00:00Z"),
    //         updatedAt: new Date("2024-05-11T00:00:00Z"),
    //         createdBy: { firstName: "Emile", lastName: "Corvini" },
    //         iterationLength: 0
    //       }
    //     ],
    //     createdAt: new Date("2024-05-11T00:00:00Z"),
    //     updatedAt: new Date("2024-05-11T00:00:00Z"),
    //     createdBy: { firstName: "Emile", lastName: "Corvini" },
    //     iterationLength: 0
    //   }
    // ];
    
    fromJson(json: Thread): Thread {
        return Thread.fromJson(json);
    }

    toJson(model: Thread): Thread {
        return Thread.toJson(model);
    }

    getThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list`;
        return super.getAll(token);
    }

    getFirstThreads(token: string): Observable<APICallReturn | Thread[] | undefined> {
        this.resourceUrl = `${this.baseUrl}/list/first`;
        return super.getAll(token);
    }

    getThread(token: string, uuid: string): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/info`;
        return super.getById(token, uuid);
    }
    
    deleteThread(token: string, uuid: string): Observable<APICallReturn | Thread> {
        this.resourceUrl = `${this.baseUrl}/delete/${uuid}`;
        return super.delete(token);
    }
    
    updateThread(token: string, thread: Thread, uuid: string): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/update/${uuid}`;
        return super.update(token, thread);
    }

    createThread(token: string, thread: Thread): Observable<APICallReturn | Thread | undefined> {
        this.resourceUrl = `${this.baseUrl}/add`;
        console.log('thread to send', thread);
        console.log('resourceUrl', this.resourceUrl);
        return super.create(thread, token);
    }


}
