  <!-- <p-breadcrumb [home]="{icon: 'pi pi-home', routerLink: '/'}" [model]="this.items"></p-breadcrumb> -->
  
  <!-- <div class="mb-10 flex items-center">
    <span class="p-float-label w-full">
        <input pInputText id="url" class="w-full" type="text" formControlName="title" />
        <label for="url">Titre</label>
    </span>
</div> -->

  <div class="container mx-auto p-4">
    <ul class="list-disc pl-5" >
      <ng-container>
          <div class="pt-10 p-4 border rounded shadow-sm">
            <form [formGroup]="threadForm.form">
              
              <!-- <p><strong>Title:</strong> <input type="text" formControlName="title"></p>
              <p *ngIf="threadForm.form.get('title')?.invalid && threadForm.form.get('title')?.touched" class="text-danger">Title is required.</p> -->
              
              <div class="mb-10 flex items-center">
                <span class="p-float-label w-1/10">
                    <input pInputText id="url" class="w-full" type="text" formControlName="title" />
                    <label for="title">Titre</label>
                </span>
              </div>
              
              <!-- <p><strong>Message:</strong> <input type="text" formControlName="message"></p>
              <p *ngIf="threadForm.form.get('message')?.invalid && threadForm.form.get('message')?.touched" class="text-danger">Message is required.</p> -->

              <div class="mb-10 flex items-center">
                <span class="p-float-label w-1/6">
                    <textarea pInputText id="url" class="w-full" type="text" formControlName="message"></textarea>
                    <label for="message">Réponse</label>
                </span>
              </div>
              
              <div class="space-x-4">
                <button pButton [loading]="isLoading" [label]="this.getButtonLabel()" class="p-button-raised p-button-sm w-fit" (click)="this.isNew ? create() : save()"></button>
                <button pButton [loading]="isLoading" *ngIf="!this.isNew"  label="Delete"  severity="danger" class="p-button-danger p-button-raised p-button-sm w-fit" (click)="delete()"></button>
              </div>
              <div *ngIf="this.threadForm.form.get('threads')?.value != null">
                <div *ngFor="let item of this.threadForm.form.get('threads')?.value">
                  <div class="border-2 border-gray-200 rounded-lg w-fit my-6 p-4 flex flex-row items-center justify-center space-x-4">
                    <p>{{ item.choice }}</p>
                    <p-button *ngIf="item.choice != 0" class="p-button-raised p-button-sm w-fit" icon="pi pi-chevron-right" (onClick)="goToThread(item.threadUUID)"></p-button>
                  </div>
                </div>
                <button pButton class="p-button-raised p-button-sm w-fit mt-2" [icon]="this.threadForm.form.get('previous')?.value != undefined ?  'pi pi-chevron-left' : 'pi pi-home' " (click)="goToThread(this.threadForm.form.get('previous')?.value)"></button>
              </div>
              <button pButton *ngIf="this.threadForm.form.get('threads')?.value != undefined" label="Ajouter une réponse" class="p-button-raised p-button-sm w-fit mt-4" (click)="addThread(this.threadForm.form.get('uuid')?.value)"></button> 
            </form>
          </div>
      </ng-container>
    </ul>
  </div>