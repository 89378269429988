<div class="w-full flex justify-center pb-10">
    <div class="card">
        <p-table
            #usersTable
            [value]="users"
            [paginator]="true"
            dataKey="id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [loading]="isLoading"
            [tableStyle]="{ 'min-width': '70rem' }"
            currentPageReportTemplate="Total {totalRecords}"
            [globalFilterFields]="['firstName', 'lastName', 'email']"
            [rowsPerPageOptions]="[5, 10, 20]"
            [selectionMode]="'single'"
        >
            <ng-template pTemplate="caption">
                <div class="flex-start">
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" [(ngModel)]="search" (input)="usersTable.filterGlobal(search, 'contains')" placeholder="Rechercher un utilisateur" class="w-72" />
                    </span>
                    <p-button class="right-5 absolute" (onClick)="create()">Créer</p-button>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 10%;">Nom</th>
                    <th style="width: 5%;">Statut</th>
                    <th style="width: 15%;">Email</th>
                    <th style="width: 30%;">Catégorie socioprofessionnelle</th>
                    <th style="width: fill-available;" pSortableColumn="updatedAt" class="flex justify-content-center align-items-center">
                        Date de modification
                        <p-sortIcon field="updatedAt"></p-sortIcon>
                    </th>
                    <th style="width: 5%;"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-user>
                <tr class="cursor-pointer" (click)="edit(user.mainUserId)">
                    <td>{{ user.firstName }} {{ user.lastName }}</td>
                    <td>{{ user.isValidated }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ user.status }}</td>
                    <td>{{ user.updatedAt | date }}</td>
                    <td (click)="edit(user.mainUserId)" class="cursor-pointer hover:bg-[#184E77] hover:text-white rounded-full">
                        <i class="pi pi-pencil cursor-pointer"></i>
                    </td>
                    <td (click)="delete(user.mainUserId)" class="cursor-pointer hover:bg-red-500 hover:text-white rounded-full">
                        <i class="pi pi-trash cursor-pointer"></i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
