import { Injectable } from '@angular/core';
import { User } from '../../models/user/user';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { APICallReturn } from '../APICallReturn';
import { UserApiService } from './user-api-service';
import { AuthService } from '../../auth';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private user: User = new User();
    private userSubject = new BehaviorSubject<User>(new User());

    constructor(
        private router: Router,
        private userApiService: UserApiService,
        private authService: AuthService,
    ) {
        const storedUser = sessionStorage.getItem('user');

        if (storedUser) {
            this.user = User.fromJson(JSON.parse(storedUser));
            this.userSubject.next(this.user);
        }
    }

    public set(user: Partial<User>): void {
        this.user = User.fromJson(user);
        this.userSubject.next(this.user);
        sessionStorage.setItem('user', JSON.stringify(this.user));
    }

    public reset(): void {
        this.user = new User();
        this.userSubject.next(this.user);
    }

    public remove(): void {
        sessionStorage.removeItem('user');
    }

    public get(): User {
        return this.user;
    }

    public getObservable(): Observable<User> {
        return this.userSubject.asObservable();
    }

    public disconnect(): void {
        this.authService.removeToken();
        this.user = new User();
        this.userSubject.next(this.user);
        sessionStorage.removeItem('user');
        this.router.navigate(['/login']);
    }

    /* CALL APIS */

    public createUser(user: User, token?: string): Observable<APICallReturn | User | undefined> {
        return this.userApiService.createUser(user, token).pipe();
    }

    public loginUser(user: Partial<User>): Observable<APICallReturn | User | undefined> {
        if (user === undefined) {
            return of(undefined);
        }
        return this.userApiService.loginUser(user).pipe(
            map((data: APICallReturn | User | undefined) => {
                if (data instanceof User && data.resetCode) {
                    this.set(data);
                    this.authService.setToken(data.resetCode);
                }
                return data;
            }),
        );
    }

    public updateUser(token: string, user: Partial<User>, id: string): Observable<APICallReturn | User | undefined> {
        if (user === undefined) {
            return of(undefined);
        }
        return this.userApiService.updateUser(token, user, id).pipe(
            map((data: APICallReturn | User | undefined) => {
                if (data instanceof User) {
                    this.set(data);
                }
                return data;
            }),
        );
    }

    public getUser(token: string, id?: string): Observable<APICallReturn | User | undefined> {
        if (!!id) {
            return this.userApiService.getUser(token, id);
        }
        return this.userApiService.getUser(token);
    }

    public getUsers(): Observable<APICallReturn | User[] | undefined> {
        return this.userApiService.getUsers(this.authService.getToken());
    }

    public deleteUser(id: string): Observable<APICallReturn | User | undefined> {
        return this.userApiService.deleteUser(this.authService.getToken(), id);
    }

    public refreshTokenUser(token: string, id: string): Observable<APICallReturn | {} | undefined> {
        return this.userApiService.refreshTokenUser(token, id);
    }
}
