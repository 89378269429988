import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
    LoginComponent,
    GuidesComponent,
    GuideEditorComponent,
    NotFoundComponent,
    DashboardComponent,
    UsersComponent,
    UserEditorComponent,
    EhpadsComponent,
    EhpadEditorComponent,
    MeetsComponent,
    MeetEditorComponent,
    DictionnaryComponent,
    ThreadsComponent,
    ThreadsEditorComponent,
    NewslettersComponent,
    NewsletterEditorComponent,
} from '@lesaidantsbackoffice/pages';
import { AuthGuard } from './core/auth/guard/auth.guard';

const routes: Routes = [
    { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'login', component: LoginComponent },
    { path: 'guides', component: GuidesComponent, canActivate: [AuthGuard] },
    { path: 'guide/:id', component: GuideEditorComponent, canActivate: [AuthGuard] },
    { path: 'newsletters', component: NewslettersComponent, canActivate: [AuthGuard] },
    { path: 'newsletter/:id', component: NewsletterEditorComponent, canActivate: [AuthGuard] },
    { path: 'ehpads', component: EhpadsComponent, canActivate: [AuthGuard] },
    { path: 'ehpad/:id', component: EhpadEditorComponent, canActivate: [AuthGuard] },
    { path: 'dictionnary', component: DictionnaryComponent, canActivate: [AuthGuard] },
    { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
    { path: 'user/:id', component: UserEditorComponent, canActivate: [AuthGuard] },
    { path: 'threads', component: ThreadsComponent, canActivate: [AuthGuard] },
    { path: 'thread/:id', component: ThreadsEditorComponent, canActivate: [AuthGuard] },
    { path: 'meets', component: MeetsComponent, canActivate: [AuthGuard] },
    { path: 'meet/:id', component: MeetEditorComponent, canActivate: [AuthGuard] },
    { path: '404', component: NotFoundComponent },
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
