import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/auth';
import { UserService } from './core/services/index-service';
import { catchError, map, of } from 'rxjs';
import { APICallReturn } from './core/services/APICallReturn';
import { User } from './core/models';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'LesAidants';
    isAuthenticated = false;

    constructor(
        private authService: AuthService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        if (this.authService.isAuthenticated()) {
            this.userService
                .getUser(this.authService.getToken())
                .pipe(
                    map((user: APICallReturn | User | undefined) => {
                        if (user instanceof User) {
                            this.userService.set(user);
                        } else {
                            this.authService.removeToken();
                            this.userService.disconnect();
                        }
                    }),
                    catchError(() => {
                        this.authService.removeToken();
                        this.userService.disconnect();
                        return of(null);
                    }),
                )
                .subscribe();
        }
        this.userService.getObservable().subscribe((user) => {
            if (user && user.email) {
                this.isAuthenticated = true;
            } else {
                this.isAuthenticated = false;
            }
        });
    }
}
